import Kiss from "../../images/kiss-1000.png";
import Scooter from "../../images/scooter-1000.png";
import Sf from "../../images/sf-1000.png";
import Pfriem from "../../images/pfriem-1000.png";
import Wine from "../../images/wine-1000.png";
import LaCebadilla from "../../images/lacebadilla-1000.png";
import Cbc from "../../images/cbc-1000.png";
import Leo from "../../images/leo-1000.png";
import PortlandHike from "../../images/portland-hike-1000.png";
import PortlandWalk from "../../images/portland-walk-1000.png";
import Blazers from "../../images/blazers-1000.png";
import Waterfall from "../../images/waterfall-1000.png";
import Shelljoel from "../../images/shelljoel-1000.png";
import Rosesplace from "../../images/rosesplace-1000.png";
import Kiki from "../../images/kiki-1000.png";
import Slappy from "../../images/slappy-1000.png";
import CbcRose from "../../images/cbc-rose-1000.png";
import Castle from "../../images/castle-1000.png";
import Bday from "../../images/bday-dinner-1000.png";
import Kiss2 from "../../images/kiss2-1000.png";
import Subway from "../../images/subway-1000.png";

import Bar from "../../images/bar-1000.png";
import Walking from "../../images/walking-1000.png";
import La from "../../images/la-1000.png";
import Fair from "../../images/fair-1000-3.png";
import Painting from "../../images/painting-1000.png";
import Ties from "../../images/ties-1000.png";
import Monster from "../../images/monster-1000.png";
import Camping from "../../images/camping-1000.png";
import PeterPiper from "../../images/peterpiper-1000.png";
import Graduation from "../../images/graduation-1000.png";
// import Vr from "../../images/vr-1000.png";
// import Burgers from "../../images/burgers-1000.png";

const pics = [
    {name: 'Rose\'s Place', img: Rosesplace, place: 'Rose\'s Place', city: 'Tucson, AZ', date: "August 18, 2021"},
    {name: 'Castello di Amorosa', img: Castle, place: 'Castello di Amorosa', city: 'Calistoga, CA', date: "August 29, 2021"},
    {name: 'AirBnB', img: Sf, place: 'AirBnB', city: 'San Francisco, CA', date: "August 29, 2021"},
    {name: 'AirBnB2', img: Kiss2, place: 'AirBnB', city: 'San Francisco, CA', date: "August 29, 2021"},
    {name: 'Scooter Ride', img: Scooter, place: 'Scooter Ride', city: 'San Francisco, CA', date: "August 30, 2021"},
    {name: 'Kiki', img: Kiki, place: 'Kiki Monster', city: 'Tucson, AZ', date: "September 6, 2021"},
    {name: 'Kiss', img: Kiss, place: 'Highwire Bar - Modest Mouse Concert', city: 'Tucson, AZ', date: "September 20, 2021"},
    {name: 'Shell and Joel\'s Place', img: Shelljoel, place: 'Michelle and Joel\'s', city: 'Portland, OR', date: "November 3, 2021"},
    {name: 'Portland Walk', img: PortlandWalk, place: 'Irvington Neighborhood Walk', city: 'Portland, OR', date: "November 4, 2021"},
    {name: 'Birthday Dinner', img: Bday, place: 'Birthday Dinner', city: 'Portland, OR', date: "November 4, 2021"},
    {name: 'Blazers', img: Blazers, place: 'Trailblazer\'s Game', city: 'Portland, OR', date: "November 5, 2021"},
    {name: 'Portland Hike', img: PortlandHike, place: 'Forest Park', city: 'Portland, OR', date: "November 6, 2021"},
    {name: 'Subway', img: Subway, place: 'Subway', city: 'Portland, OR', date: "November 6, 2021"},
    {name: 'Waterfall', img: Waterfall, place: 'Multnomah Falls', city: 'Portland, OR', date: "November 7, 2021"},
    {name: 'pfriem', img: Pfriem, place: 'Pfriem Brewery', city: 'Deschutes, OR', date: "November 7, 2021"},
    {name: 'Slappy Hands', img: Slappy, place: 'Slappy Hands', city: 'Tucson, AZ', date: "November 21, 2021"},
    {name: 'Leo', img: Leo, place: 'Leo', city: 'Tucson, AZ', date: "November 22, 2021"},
    {name: 'Cardboard Cowboy Jason', img: Cbc, place: 'Cardboard Jason', city: 'Tucson, AZ', date: "November 27, 2021"},
    {name: 'Cardboard Cowboy Rose', img: CbcRose, place: 'Cardboard Rose', city: 'Tucson, AZ', date: "December 3, 2021"},
    {name: 'Wine', img: Wine, place: 'Callaghan Vineyards', city: 'Sonoita, AZ', date: "December 18, 2021"},
    {name: 'La Cebadilla Lake', img: LaCebadilla, place: 'La Cebadilla Lake', city: 'Tucson, AZ', date: "December 24, 2021"},

    {name: "Getting Some Drinks", img: Bar, place: 'The Delta', city: 'Tucson, AZ', date: "February 26, 2021"},
    {name: "Out Walking", img: Walking, place: 'Catalina Foothills', city: 'Tucson, AZ', date: "March 5, 2021"},
    {name: "Waiting for an Uber", img: La, place: 'Los Angeles', city: 'Los Angeles, CA', date: "April 1, 2021"},
    {name: "Pima County Fair", img: Fair, place: 'Pima County Fairgrounds', city: 'Tucson, AZ', date: "April 23, 2021"},
    {name: "Painting and Wine", img: Painting, place: 'Creative Juice', city: 'Tucson, AZ', date: "May 12, 2021"},
    {name: "Walking with Ties", img: Ties, place: 'Home', city: 'Tucson, AZ', date: "May 28, 2021"},
    {name: "Goofing with the Kids", img: Monster, place: 'Home', city: 'Tucson, AZ', date: "May 28, 2021"},
    {name: "Owen's Birthday Party", img: PeterPiper, place: 'Peter Piper Pizza', city: 'Tucson, AZ', date: "June 4, 2021"},
    {name: "Camping", img: Camping, place: 'Rose Canyon Lake', city: 'Tucson, AZ', date: "June 11, 2021"},
    {name: 'Graduation', img: Graduation, place: 'La Paloma', city: 'Tucson, AZ', date: "June 24, 2021"},
];

export default pics;

// {name: 'Burgers', img: Burgers, place: 'Roam Burgers', city: 'San Francisco, CA', date: "August /*30, 2021"},
    // {name: 'VR', img: Vr, place: 'Jason\'s Office', city: 'Tucson, AZ', date: "November 21, 2021"},*/
