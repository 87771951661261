/*
 * This file is the entry point for the application and is responsible for building
 * and mounting the application.
 */

/*
 * Import the styles for the loading screen. We're doing that here to make
 * sure they get loaded regardless of the entry point for the application.
 */
import './index.css';

/*
 * React
 *
 * Establish the root component and render it to the DOM
 */

import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/css/indexOLD.css';
import App from './App';

const domElementId = 'root';

ReactDOM.render(<App />, document.getElementById(domElementId));


