import React from 'react';
import classNames from '../utils/classNames';

export default function ImageCard(props) {
  const { children, className, src, alt="" } = props;

  return (
    <div className={classNames(
      `aspect-w-3 aspect-h-2`,
      className,
    )}>
      <img className="object-cover shadow-lg rounded-lg"
         src={src}
         alt={alt}
      />
        {children}
    </div>
  )
}
