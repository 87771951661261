import React from 'react';
// import { Routes, Route, Navigate } from 'react-router-dom';  // v6
import { Switch, Route, Redirect } from 'react-router-dom';

/**
 * The AuthenticatedRoute provides an easy way to redirect the user
 * to a login experience if we don't know who they are.
 */

// import AuthenticatedRoute from './routes/AuthenticatedRoute';
// import AuthenticatedSetupRoute from './routes/AuthenticatedSetupRoute';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */
import Home from './pages/home/Layout';

export default (
  <Switch>
    <Route path="/home" component={Home}/>

    <Redirect exact from="/" to="/home" />

    {/*<AuthenticatedSetupRoute exact path="/setup/name" component={SetupNameLayout} />*/}
    {/*<AuthenticatedRoute exact path="/projects" component={ProjectsLayout} />*/}
    {/*<AuthenticatedRoute exact path="/settings" component={SettingsLayout} />*/}
    {/*<Route component={NotFoundPage} />*/}
  </Switch>
);
