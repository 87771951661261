import React from 'react';
import {BrowserRouter} from "react-router-dom";
import routes from './routes';
// import {ThemeProvider} from "@mui/material/styles";
// import Theme from "/src/constants/MuiTheme";

function App(props) {

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );
}

export default App;
