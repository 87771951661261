import React from 'react';
import ImageCard from "../../components/ImageCard";
import pics from "./Pics";

// &#128123;  - Ghost html code
export default function Layout(props) {

  return (
    <main className="bg-stone-100">
      <div className="relative overflow-hidden">
        <div className="py-12 px-4 sm:px-6 lg:px-8 lg:py-24">

          <div className="mx-auto space-y-12 text-gray-700">

            <div className="space-y-5 sm:space-y-4  text-center ">
              <h1 className="text-4xl font-extrabold tracking-tight sm:text-4xl">Love you boo  &#10084;</h1>
              <p className="text-2xl font-medium text-gray-500">Happy one year!
                </p>
            </div>

            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
              {
                pics.map((pic, index) => {
                  return (
                    <li key={index}>
                      <div className="space-y-4">
                        {/*Image*/}
                        <ImageCard
                          src={pic.img}
                        />

                        {/*Text*/}
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{pic.place}</h3>
                            <div className="text-sm text-gray-400">
                              <p className="">{pic.city}</p>
                              <p className="">{pic.date}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }

            </ul>
          </div>
        </div>
      </div>
      <footer className="items-center center-text text-center pb-4">
        <div>
          Powered by &#10084;
        </div>
      </footer>
    </main>
  );
}
